import axios from "axios";
import moment from "moment";
import {
	getCurrentProgram,
	getSignedInUser,
} from "../../../Auth/services/user";
import { checkIfUserAlreadyExists } from "../../../Auth/services/signup";

import { uploadDocumentsToStorage } from "services/firebase/firebaseStorage";
import { getPermissionCode } from "../../../Auth/services/permissions";

export const automateBookingSubmission = async (data) => {
	let { intake, errorCallback, callback, event } = data;
	let emailMsg = "";
	let completeMsg = "";

	try {
		let { authUser, userData } = getSignedInUser();
		let authUid = authUser?.uid;

		if (!authUid) {
			userData = await checkIfUserAlreadyExists(authUser.email);
			authUid = userData?.authUid;
		}

		let programCode = getPermissionCode(
			getCurrentProgram(),
			userData?.permissions
		);

		switch (intake.channel.toLowerCase()) {
			case "viber":
			case "landline phone call":
				intake.contactNum =
					intake.channel === "Viber"
						? intake.viberNumber
						: intake.landlineNumber;
				delete intake.viberNumber;
				delete intake.landlineNumber;
				break;
			case "google meet":
			case "mobile phone call":
			default:
				delete intake.viberNumber;
				delete intake.landlineNumber;
				intake.contactNum = userData.mobileNumber;
				break;
		}

		switch (intake.channel.toLowerCase()) {
			case "google meet":
				emailMsg = `If you are not in the call within 10 minutes, your appointment will be cancelled. To reschedule, book another consult at ${process.env.GATSBY_WEBSITE_URL}`;
				completeMsg = `We have sent a calendar invite to ${userData.email}. Please click the join GMeet button to attend your consult.`;
				break;
			default:
				emailMsg = `If your number is busy and we are not able to reach you within 10 minutes, your appointment will be cancelled. To reschedule, book another consult at ${process.env.GATSBY_WEBSITE_URL}`;
				completeMsg = `Please wait for our doctor to call you at ${intake.contactNum}.`;
				break;
		}

		let uploadedDocumentUrls;
		if (intake?.documents?.length) {
			uploadedDocumentUrls = await uploadDocumentsToStorage({
				documents: intake.documents,
				path: `/${userData.email}/supportingDocuments`,
				docBaseName: `${moment().format("YYYYMMDD")}`,
				docType: "supportingdocuments",
			});
			uploadedDocumentUrls = uploadedDocumentUrls.map((file) => file.url);
		}

		let PAYLOAD = {
			...intake,
			userUid: userData.id,
			calendlyEventUrl: event,
			email: userData.email,
			name: userData.firstName,
			emailMsg,
			completeMsg,
			doctorLink:
				process.env.GATSBY_AIRTABLE_TELECONSULT_OPS_INTERFACE_BASE_URL,
			uploadedDocumentUrls,
			chiefComplaint: intake.chiefComplaint?.value,
			programCode,
		};

		let response = await axios.post(
			process.env.GATSBY_INTEGROMAT_CREATE_BOOKING_AUTOMATION_SUBMISSION_URL,
			PAYLOAD
		);

		return callback(response);
	} catch (e) {
		console.log(e);
		if (errorCallback) errorCallback();
	}
};

export const automateMedstaffBookingSubmission = async (data) => {
	let { intake, errorCallback, callback, event } = data;
	let emailMsg = "";
	let completeMsg = "";

	let { medstaffData } = getSignedInUser();

	try {
		switch (intake.channel.toLowerCase()) {
			case "viber":
			case "landline number":
				intake.contactNum =
					intake.channel === "Viber"
						? intake.viberNumber
						: intake.landlineNumber;
				delete intake.viberNumber;
				delete intake.landlineNumber;
				break;
			case "google meet":
			case "mobile phone number":
			default:
				delete intake.viberNumber;
				delete intake.landlineNumber;
				intake.contactNum = intake.mobileNumber;
				break;
		}

		switch (intake.channel.toLowerCase()) {
			case "google meet":
				emailMsg = `If you are not in the call within 10 minutes, your appointment will be cancelled.`;
				completeMsg = `We have sent a calendar invite to ${intake.email}. Please click the join GMeet button to attend your consult.`;
				break;
			default:
				emailMsg = `If your number is busy and we are not able to reach you within 10 minutes, your appointment will be cancelled.`;
				completeMsg = `Please wait for our doctor to call you at ${intake.contactNum}.`;
				break;
		}

		// let uploadedDocumentUrls
		// if (intake?.documents?.length) {
		//   uploadedDocumentUrls = await uploadDocumentsToStorage({
		//     documents: intake.documents,
		//     path: `/${intake.email}/supportingDocuments`,
		//     docBaseName: `${moment().format("YYYYMMDD")}`,
		//     docType: "supportingdocuments",
		//   })
		//   uploadedDocumentUrls = uploadedDocumentUrls.map((file) => file.url)
		// }

		let PAYLOAD = {
			...intake,
			calendlyEventUrl: event,
			emailMsg,
			completeMsg,
			doctorLink:
				process.env.GATSBY_AIRTABLE_TELECONSULT_OPS_INTERFACE_BASE_URL,
			uploadedDocumentUrls: [],
			website: process.env.GATSBY_WEBSITE_URL,
			prcNumber: medstaffData.fields.prcNumber,
			chiefComplaint: intake.chiefComplaint?.value,
		};

		let response = await axios.post(
			process.env.GATSBY_INTEGROMAT_MEDSTAFF_BOOKING_SUBMISSION_BASE_URL,
			PAYLOAD
		);
		return callback(response);
	} catch (e) {
		console.error(e);
		if (errorCallback) errorCallback();
	}
};
